import { purify } from "@vfit/shared/themes";
export var organizeVoucherInsertionCard = function(cmsData, voucher) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24;
    return {
        vouchers: {
            insertion: {
                title: (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.vouchers) === null || ref === void 0 ? void 0 : (ref1 = ref.insertion) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
                message: (cmsData === null || cmsData === void 0 ? void 0 : (ref2 = cmsData.vouchers) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.insertion) === null || ref3 === void 0 ? void 0 : ref3.message) || "",
                inputName: (cmsData === null || cmsData === void 0 ? void 0 : (ref4 = cmsData.vouchers) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.insertion) === null || ref5 === void 0 ? void 0 : ref5.inputName) || "",
                buttonLabel: (cmsData === null || cmsData === void 0 ? void 0 : (ref6 = cmsData.vouchers) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.insertion) === null || ref7 === void 0 ? void 0 : ref7.buttonLabel) || "",
                expiredMessage: (cmsData === null || cmsData === void 0 ? void 0 : (ref8 = cmsData.vouchers) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.insertion) === null || ref9 === void 0 ? void 0 : ref9.expiredMessage) || "",
                invalidMessage: (cmsData === null || cmsData === void 0 ? void 0 : (ref10 = cmsData.vouchers) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.insertion) === null || ref11 === void 0 ? void 0 : ref11.invalidMessage) || "",
                skipLabel: (cmsData === null || cmsData === void 0 ? void 0 : (ref12 = cmsData.vouchers) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.insertion) === null || ref13 === void 0 ? void 0 : ref13.skipLabel) || "salta"
            },
            success: {
                title: (cmsData === null || cmsData === void 0 ? void 0 : (ref14 = cmsData.vouchers) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.success) === null || ref15 === void 0 ? void 0 : ref15.title) || "",
                subtitle: cmsData === null || cmsData === void 0 ? void 0 : (ref16 = cmsData.vouchers) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.success) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.subtitle) === null || ref18 === void 0 ? void 0 : ref18.replace("$VOUCHER", voucher || ""),
                loadingMessage: ((ref21 = purify([
                    (cmsData === null || cmsData === void 0 ? void 0 : (ref19 = cmsData.vouchers) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.success) === null || ref20 === void 0 ? void 0 : ref20.loadingMessage) || ""
                ])) === null || ref21 === void 0 ? void 0 : ref21[0]) || "",
                backTitle: ((ref24 = purify([
                    (cmsData === null || cmsData === void 0 ? void 0 : (ref22 = cmsData.vouchers) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.success) === null || ref23 === void 0 ? void 0 : ref23.backTitle) || ""
                ])) === null || ref24 === void 0 ? void 0 : ref24[0]) || ""
            }
        }
    };
};
