export var organizeCoverageToolCheckNumberCard = function(props) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    var prefix = props === null || props === void 0 ? void 0 : (ref = props.personalinfo) === null || ref === void 0 ? void 0 : ref.lockIn;
    var modalError = {
        title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.modalNewUser) === null || ref1 === void 0 ? void 0 : ref1.title) || "Non sei ancora un nostro cliente?",
        description: (prefix === null || prefix === void 0 ? void 0 : (ref2 = prefix.modalNewUser) === null || ref2 === void 0 ? void 0 : ref2.description) || "Il tuo numero non sembra essere di un nostro cliente, ma non ti preoccupare, abbiamo altre offerte pronte a stupirti!",
        editLabel: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.modalNewUser) === null || ref3 === void 0 ? void 0 : ref3.buttonModifyNumber) || "MODIFICA NUMERO",
        submitLabel: (prefix === null || prefix === void 0 ? void 0 : (ref4 = prefix.modalNewUser) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.action) === null || ref5 === void 0 ? void 0 : ref5.title) || "SCOPRI L'OFFERTA MIGLIORE PER TE"
    };
    var checkNumberProps = {
        title: (prefix === null || prefix === void 0 ? void 0 : (ref6 = prefix.personalInfo) === null || ref6 === void 0 ? void 0 : ref6.title) || "Ciao!",
        description: (prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.personalInfo) === null || ref7 === void 0 ? void 0 : ref7.description) || "Inserisci qui il tuo nome, cognome e numero di telefono Vodafone, per scoprire l’offerta che ti abbiamo riservato.",
        firstNameLabel: (prefix === null || prefix === void 0 ? void 0 : (ref8 = prefix.personalInfo) === null || ref8 === void 0 ? void 0 : ref8.nameLabel) || "Nome",
        lastNameLabel: (prefix === null || prefix === void 0 ? void 0 : (ref9 = prefix.personalInfo) === null || ref9 === void 0 ? void 0 : ref9.surnameLabel) || "Cognome",
        phoneNumberLabel: "Numero di telefono",
        ctaLabel: (prefix === null || prefix === void 0 ? void 0 : (ref10 = prefix.personalInfo) === null || ref10 === void 0 ? void 0 : ref10.buttonLabel) || "verifica il numero di telefono",
        goNext: "Prosegui"
    };
    return {
        modalError: modalError,
        checkNumberProps: checkNumberProps
    };
};
