import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { errorMock, isMobileProduct } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * This method calls the Retrieve multiline service.
 * SearchParams: relatedFlow, relatedEntityType, partyPrivacyType
 * @param individualId
 * @param payload
 * @param customOptions
 * @returns
 */ export var retrieveMultiLinesService = function(individualId, payload, customOptions) {
    return nextClient.post("".concat(API.INDIVIDUAL, "/").concat(individualId, "/").concat(API.RETRIEVE_MULTILINES), payload, _object_spread({
        searchParams: {
            relatedFlow: "Provide",
            relatedEntityType: "Subscriber",
            partyPrivacyType: "VF"
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation for saving into the key 'retrieveMultiLinesQuery'
 * the response coming from the API service retrieveMultiLinesService.
 * @returns
 */ export var useRetrieveMultiLines = function() {
    var queryClient = useQueryClient();
    return useMutation("retrieveMultiLinesMutation", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24;
        var customerData = getFromLocalStorageByKey("customerData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var dataSimCartItemId = (ref = shoppingCart) === null || ref === void 0 ? void 0 : (ref1 = ref.cartItem) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.dataSim) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.product) === null || ref5 === void 0 ? void 0 : ref5.id;
        var internetCartItemId = (ref6 = shoppingCart) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.cartItem) === null || ref7 === void 0 ? void 0 : (ref8 = ref7[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.internet) === null || ref9 === void 0 ? void 0 : (ref10 = ref9[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.product) === null || ref11 === void 0 ? void 0 : ref11.id;
        var fixedVoiceCartItemId = (ref12 = shoppingCart) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.cartItem) === null || ref13 === void 0 ? void 0 : (ref14 = ref13[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.fixedVoice) === null || ref15 === void 0 ? void 0 : (ref16 = ref15[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.product) === null || ref17 === void 0 ? void 0 : ref17.id;
        var productId = (ref18 = shoppingCart) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.cartItem) === null || ref19 === void 0 ? void 0 : (ref20 = ref19[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.product) === null || ref21 === void 0 ? void 0 : ref21.id;
        var individualId = ((ref22 = customerData === null || customerData === void 0 ? void 0 : customerData[0]) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.owningIndividual) === null || ref23 === void 0 ? void 0 : ref23.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref24 = findCaller.individualRef) === null || ref24 === void 0 ? void 0 : ref24.id);
        var subscribers = null;
        if (isMobileProduct()) {
            subscribers = [
                {
                    productId: productId
                }, 
            ];
        } else {
            subscribers = [
                {
                    productId: dataSimCartItemId
                },
                {
                    productId: internetCartItemId
                },
                {
                    productId: fixedVoiceCartItemId
                }, 
            ];
        }
        var payload = {
            subscribers: subscribers
        };
        return retrieveMultiLinesService(individualId, payload);
    }, {
        onSuccess: function(data) {
            // save new retrieveMultiLines object into storage
            localStorage.setItem("retrieveMultiLines", JSON.stringify(data));
            localStorage.setItem("oldPrivacyProfile", JSON.stringify(data));
            queryClient.setQueryData("retrieveMultiLines", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - retrieveMultiLines: ", error);
            queryClient.setQueryData("retrieveMultiLines", errorMock("retrieveMultiLines", error));
            localStorage.setItem("retrieveMultiLines", JSON.stringify(errorMock("retrieveMultiLines", error)));
        }
    });
};
