import { getFormattedAddress, Placeholders } from "@vfit/consumer/data-access";
import { checkWindow, purifyTextWithLogo } from "@vfit/shared/data-access";
import { purify } from "@vfit/shared/themes";
var evaluateCmsData = function(rawCmsData, address, technology, userType, price, originalPrice) {
    var ref;
    var droppedCartData = rawCmsData === null || rawCmsData === void 0 ? void 0 : rawCmsData.droppedcarthomepage;
    var addressFromCookie = (address === null || address === void 0 ? void 0 : address.displayedAddress) === "undefined" ? "" : address === null || address === void 0 ? void 0 : address.displayedAddress;
    var formattedAddress = getFormattedAddress(addressFromCookie || "");
    var formattedTechnology = (technology === null || technology === void 0 ? void 0 : technology.displayedTecnology) || "";
    var isPanicModeEnabled = checkWindow() && window.isPanicModeFixed;
    var ctaLabelByUserType = userType === "legacy" || isPanicModeEnabled ? droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.ctaLabelAlternative : droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.ctaLabel;
    var handlePlaceholders = function(text) {
        return purifyTextWithLogo(text.replace(Placeholders.TECHNOLOGY, formattedTechnology).replace(Placeholders.ADDRESS, formattedAddress).replace(Placeholders.PRICE, price || "").replace(Placeholders.ORIGINAL_PRICE, originalPrice || ""));
    };
    var handleSpecs = function(specs) {
        return specs === null || specs === void 0 ? void 0 : specs.map(function(spec) {
            return {
                value: handlePlaceholders((spec === null || spec === void 0 ? void 0 : spec.value) || "")
            };
        });
    };
    return {
        image: droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.image,
        imageMobile: droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.imageMobile,
        category: (droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.category) || "",
        title: (droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.title) || "",
        description: (ref = purify([
            handlePlaceholders((droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.description) || "") || ""
        ])) === null || ref === void 0 ? void 0 : ref[0],
        specs: handleSpecs(droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.specs) || [
            {
                value: ""
            }
        ],
        ctaLabel: ctaLabelByUserType || ""
    };
};
export { evaluateCmsData };
