import { ErrorCodes } from "@vfit/consumer/data-access";
var checkError = function(errorCode, handleOnClose, errorMessge) {
    var title = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.title) || "";
    var message = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.description) || "";
    var actionText = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.buttonLabel) || "";
    var onClose = handleOnClose;
    var actionEvent = handleOnClose;
    if (errorCode === ErrorCodes.FISCAL_CODE_UNDERAGE) {
        message = "Devi essere maggiorenne per proseguire";
        onClose = undefined;
        actionEvent = undefined;
    }
    if (errorCode === ErrorCodes.FISCAL_CODE_ERROR) {
        message = "Il codice fiscale non \xe8 valido. Inserisci un codice fiscale valido per proseguire";
        onClose = undefined;
        actionEvent = undefined;
    }
    return {
        title: title,
        message: message,
        actionText: actionText,
        onClose: onClose,
        actionEvent: actionEvent
    };
};
export { checkError };
export var organizeNameFiscalCode = function(nameFiscalCodeCms, product) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19;
    return {
        legacyPopup: {
            legacyPopupIsOpen: true,
            legacyPopupTitle: (product === null || product === void 0 ? void 0 : (ref = product.commonOverride) === null || ref === void 0 ? void 0 : (ref1 = ref.legacyCtc) === null || ref1 === void 0 ? void 0 : ref1.title) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.clientRecognition) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.redirect) === null || ref4 === void 0 ? void 0 : ref4.title) || "",
            legacyPopupMessage: (product === null || product === void 0 ? void 0 : (ref5 = product.commonOverride) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.legacyCtc) === null || ref6 === void 0 ? void 0 : ref6.description) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref7 = nameFiscalCodeCms.personalinfo) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.clientRecognition) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.redirect) === null || ref9 === void 0 ? void 0 : ref9.description) || "",
            legacyPopupActionText: (product === null || product === void 0 ? void 0 : (ref10 = product.commonOverride) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.legacyCtc) === null || ref11 === void 0 ? void 0 : ref11.buttonLabel) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref12 = nameFiscalCodeCms.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.clientRecognition) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.redirect) === null || ref14 === void 0 ? void 0 : ref14.buttonLabel) || "",
            legacyPopupUrl: (product === null || product === void 0 ? void 0 : (ref15 = product.commonOverride) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.legacyCtc) === null || ref16 === void 0 ? void 0 : ref16.url) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref17 = nameFiscalCodeCms.personalinfo) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.clientRecognition) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.redirect) === null || ref19 === void 0 ? void 0 : ref19.url) || ""
        }
    };
};
export var organizePersonalInfo = function(dataFromCms) {
    var ref, ref1;
    var prefix = dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : ref1.personalInfo;
    var dataCard = {
        title: (prefix === null || prefix === void 0 ? void 0 : prefix.title) || "",
        description: (prefix === null || prefix === void 0 ? void 0 : prefix.description) || "",
        bottomText: (prefix === null || prefix === void 0 ? void 0 : prefix.bottomText) || "",
        lableName: (prefix === null || prefix === void 0 ? void 0 : prefix.labelName) || "",
        lableFiscalCode: (prefix === null || prefix === void 0 ? void 0 : prefix.lableFiscalCode) || "",
        action: (prefix === null || prefix === void 0 ? void 0 : prefix.textAction) || "",
        url: (prefix === null || prefix === void 0 ? void 0 : prefix.url) || "https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html"
    };
    return dataCard;
};
export var organizeRetriveFiscalCode = function(nameFiscalCodeCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34;
    return {
        retriveFiscalCode: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref = nameFiscalCodeCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.fiscalCode) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
            firstName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref4 = nameFiscalCodeCms.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "",
            lastName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref6 = nameFiscalCodeCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "",
            birthDate: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref8 = nameFiscalCodeCms.personalinfo) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.selectInput) === null || ref9 === void 0 ? void 0 : ref9.birthdate) || "",
            gender: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref10 = nameFiscalCodeCms.personalinfo) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.gender) || "",
            nationality: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref12 = nameFiscalCodeCms.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.country) || "",
            birthPlace: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref14 = nameFiscalCodeCms.personalinfo) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.selectInput) === null || ref15 === void 0 ? void 0 : ref15.city) || "",
            errorMessage: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref16 = nameFiscalCodeCms.personalinfo) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.generalInfo) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.errorMessage) === null || ref18 === void 0 ? void 0 : ref18.error2) || "",
            birthNation: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref19 = nameFiscalCodeCms.personalinfo) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.selectInput) === null || ref20 === void 0 ? void 0 : ref20.birthNation) || "Nazione di nascita",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref21 = nameFiscalCodeCms.personalinfo) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.fiscalCode) === null || ref22 === void 0 ? void 0 : ref22.buttonLabel) || ""
        },
        nameFiscalCodeCard: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref23 = nameFiscalCodeCms.personalinfo) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.generalInfo) === null || ref24 === void 0 ? void 0 : ref24.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref25 = nameFiscalCodeCms.personalinfo) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.generalInfo) === null || ref26 === void 0 ? void 0 : ref26.description) || "",
            firstNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref27 = nameFiscalCodeCms.personalinfo) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.selectInput) === null || ref28 === void 0 ? void 0 : ref28.firstName) || "",
            lastNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref29 = nameFiscalCodeCms.personalinfo) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.selectInput) === null || ref30 === void 0 ? void 0 : ref30.lastName) || "",
            fiscalCodeLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref31 = nameFiscalCodeCms.personalinfo) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.selectInput) === null || ref32 === void 0 ? void 0 : ref32.fiscalCode) || "",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref33 = nameFiscalCodeCms.personalinfo) === null || ref33 === void 0 ? void 0 : (ref34 = ref33.generalInfo) === null || ref34 === void 0 ? void 0 : ref34.fcMissingLabel) || ""
        }
    };
};
