import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock, retrieveProduct } from "@vfit/consumer/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * This method calls the service that notify the user with an email showing the offer details.
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */ var notifyService = function(payload, customOptions) {
    return nextClient.post("".concat(API.CARE_V1, "/").concat(API.NOTIFY), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run createCustomer using the createCustomer, the customerData and the shoppingCart.
 * Call this after the customer and the shoppingCart have just been created.
 * @returns
 */ export var useNotify = function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    var queryClient = useQueryClient();
    var product = retrieveProduct();
    var customerData = getFromLocalStorageByKey("customerData");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var owningIndividualId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.id;
    var email = customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.owningIndividual) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.contactMedium) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.emailAddress;
    var firstName = customerData === null || customerData === void 0 ? void 0 : (ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.firstName;
    var lastName = customerData === null || customerData === void 0 ? void 0 : (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.lastName;
    var payload;
    if (!!owningIndividualId && !!shoppingCart) {
        payload = {
            template: "sendContractSummary",
            lastName: lastName,
            firstName: firstName,
            email: email,
            orderId: cartId,
            contractSummaryLink: [
                "https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/".concat((product === null || product === void 0 ? void 0 : product.offerId) || "3556298", ".pdf"), 
            ],
            individualId: owningIndividualId
        };
    }
    return useMutation([
        "notifyService"
    ], function() {
        return notifyService(payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("notify", "1");
            queryClient.setQueryData("notify", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - notify: ", error);
            queryClient.setQueryData("notify", errorMock("notify", error));
            localStorage.setItem("notify", JSON.stringify(errorMock("notify", error)));
        }
    });
};
