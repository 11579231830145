import { getRedirectCoverage } from "@vfit/consumer/data-access";
export var Placeholders;
(function(Placeholders) {
    Placeholders["TECHNOLOGY"] = "$TECHNOLOGY";
    Placeholders["ADDRESS"] = "$ADDRESS";
    Placeholders["PRICE"] = "$PRICE";
    Placeholders["ORIGINAL_PRICE"] = "$ORIGINAL_PRICE";
})(Placeholders || (Placeholders = {}));
export var evaluateOfferDetails = function(productsAll, cmsId, isPanicMode) {
    var ref;
    if (!productsAll || (productsAll === null || productsAll === void 0 ? void 0 : productsAll.length) === 0 || !cmsId || cmsId === "") return null;
    var product = productsAll.find(function(p) {
        return (p === null || p === void 0 ? void 0 : p.cmsId) === cmsId;
    });
    return {
        price: (product === null || product === void 0 ? void 0 : product.price) || "",
        originalPrice: (product === null || product === void 0 ? void 0 : product.originalPrice) || "",
        recurrence: (product === null || product === void 0 ? void 0 : product.recurrence) || "",
        offerName: (product === null || product === void 0 ? void 0 : product.category) || "",
        offerDescription: (product === null || product === void 0 ? void 0 : product.description) || "",
        urlClickToCall: isPanicMode ? ((ref = product.panicModeAction) === null || ref === void 0 ? void 0 : ref.url) || "" : (product === null || product === void 0 ? void 0 : product.redirectR02) || "",
        redirectUrl: getRedirectCoverage((product === null || product === void 0 ? void 0 : product.redirectCoveragePath) || "") || ""
    };
};
export var getTechByKey = function(techs, key) {
    return (techs === null || techs === void 0 ? void 0 : techs[key.toLowerCase()]) || (techs === null || techs === void 0 ? void 0 : techs[key.toUpperCase()]);
};
export var getFormattedAddress = function(address) {
    var formattedAddress = address.toLowerCase().split(" ").map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
    return handleRomanNumber(formattedAddress);
};
export var handleRomanNumber = function(str) {
    var romanNumeralRegex = /\b(M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3}))\b/gi;
    return str.replace(romanNumeralRegex, function(match) {
        return match.toUpperCase();
    });
};
