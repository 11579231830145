import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * Recall service POST /validateVoucher
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var validateVoucherService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.VALIDATE_VOUCHER), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run validateVoucherService.
 * @returns
 */ export var useValidateVoucher = function() {
    var queryClient = useQueryClient();
    var keyString = "validateVoucher";
    return useMutation([
        "doValidateVoucher"
    ], function(param) {
        var voucherId = param.voucherId;
        var ref;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var customerData = getFromLocalStorageByKey("customerData");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        var payload = {
            voucherId: voucherId,
            customerId: customerId
        };
        return validateVoucherService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
