export var IWidgetType;
(function(IWidgetType) {
    IWidgetType["PRODUCTS_SLIDER"] = "SLIDER_PRODUCTS";
    IWidgetType["DEFAULT_SLIDER"] = "SLIDER_DEFAULT";
    IWidgetType["EXP_SLIDER"] = "SLIDER_EXPERIENCES";
    IWidgetType["QR_MODULE"] = "QR_MODULE";
    IWidgetType["LARGE_SMALL_SLIDER"] = "SLIDER_LARGE_SMALL";
    IWidgetType["LARGE_LARGE_SLIDER"] = "SLIDER_LARGE_LARGE";
    IWidgetType["ANCHORS"] = "ANCHORS";
    IWidgetType["IMAGE_TITLE_DESCRIPTION"] = "SLIDER_IMAGE_TITLE_DESCRIPTION";
    IWidgetType["ICON_TITLE_DESCRIPTION"] = "SLIDER_ICON_TITLE_DESCRIPTION";
    IWidgetType["FAQ"] = "FAQ";
    IWidgetType["SPLITTED_IMAGE_TEXT"] = "SPLITTED_IMAGE_TEXT";
    IWidgetType["HUB"] = "HUB";
    IWidgetType["HUB_DEVICES"] = "HUB_DEVICES";
    IWidgetType["HUB_CUSTOM"] = "HUB_CUSTOM";
    IWidgetType["HUB_SOLUTIONS"] = "HUB_SOLUTIONS";
    IWidgetType["HUB_SOLUTIONS_CUSTOM"] = "HUB_SOLUTIONS_CUSTOM";
    IWidgetType["HUB_WINBACK"] = "HUB_WINBACK";
    IWidgetType["SHOPPING_CART_TECHNOLOGIES"] = "SHOPPING_CART_TECHNOLOGIES";
    IWidgetType["VIDEO"] = "VIDEO";
    IWidgetType["TEXT"] = "TEXT";
    IWidgetType["FREE_HTML"] = "FREE_HTML";
    IWidgetType["TOP_HERO"] = "TOP_HERO";
    IWidgetType["DOCUMENTS"] = "DOCUMENTS";
    IWidgetType["TEXT_ACTION"] = "TEXT_ACTION";
    IWidgetType["TABS_WIDGETS"] = "TABS";
    IWidgetType["FORMS"] = "FORMS";
    IWidgetType["DROPPED_CART"] = "DROPPED_CART";
    IWidgetType["STICKY_BANNER_FOOTER"] = "FLOATING_BANNER";
})(IWidgetType || (IWidgetType = {}));
