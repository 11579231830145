import { getSelectedProductOfferId } from "@vfit/consumer/data-access";
import { checkWindow } from "@vfit/shared/data-access";
var checkFwaRedirect = function(product, allProducts) {
    var filteredOfferID = getSelectedProductOfferId();
    var productRedirect;
    var fwaRedirect = false;
    if (filteredOfferID !== undefined) {
        var isAlternativeOffer = filteredOfferID != (product === null || product === void 0 ? void 0 : product.offerId);
        var checkTechFWA = checkWindow() && localStorage.getItem("isTechFWA") !== "undefined" && JSON.parse(localStorage.getItem("isTechFWA")) === true;
        fwaRedirect = isAlternativeOffer && checkTechFWA;
        if (fwaRedirect) {
            productRedirect = allProducts === null || allProducts === void 0 ? void 0 : allProducts.find(function(productForSlug) {
                return (productForSlug === null || productForSlug === void 0 ? void 0 : productForSlug.offerId) == filteredOfferID;
            });
        }
    }
    return {
        fwaRedirect: fwaRedirect,
        productRedirect: productRedirect
    };
};
export { checkFwaRedirect };
