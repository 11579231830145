import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method calls the individualService looking for a specific constactID (or OwningIndividualId)
 * Method: GET
 * @param contactId
 * @param customOptions
 * @returns
 */ var individualService = function(contactId, customOptions) {
    return nextClient.get("".concat(API.INDIVIDUAL, "/").concat(contactId), _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method calls the individualService
 * @param customOptions
 * @returns
 */ export var useIndividual = function(customOptions) {
    var ref, ref1;
    var customerData = getFromLocalStorageByKey("customerData");
    var contactId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.id;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "individual";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return individualService(contactId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            onSuccess: function(data) {
                localStorage.setItem("individual", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - individual: ", error);
                localStorage.setItem("individual", JSON.stringify(errorMock("individual", error)));
                return errorMock("individual", error);
            }
        })
    };
    return handleUseQuery(options);
};
