import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * This method is used to invoke the POST of linkContactAndCustomer service request.
 * @param payload
 * @param customOptions
 */ var linkContactAndCustomerService = function(payload, customOptions) {
    return nextClient.post(API.LINK_CONTACT_CUSTOMER, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run linkContactAndCustomerService using the IPickUpLocation.
 * @param pickupPoint
 * @returns
 */ export var useLinkContactAndCustomer = function(pickupPoint) {
    var queryClient = useQueryClient();
    return useMutation("linkContactAndCustomer", function(addressIdPayload) {
        var ref, ref1, ref2;
        // Get addressID from deliveryCreatedAddress data saved into local storage.
        var addressId;
        if (addressIdPayload) {
            addressId = addressIdPayload;
        } else if (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) {
            var ref3;
            addressId = pickupPoint === null || pickupPoint === void 0 ? void 0 : (ref3 = pickupPoint.locationAddress) === null || ref3 === void 0 ? void 0 : ref3.id;
        } else {
            var ref4;
            var createdAddress = getFromLocalStorageByKey("deliveryCreatedAddress");
            addressId = createdAddress ? (ref4 = createdAddress) === null || ref4 === void 0 ? void 0 : ref4.id : "";
        }
        // Get contactID from individual data saved into local storage.
        var individual = getFromLocalStorageByKey("individual");
        var contactObjId = (ref = individual) === null || ref === void 0 ? void 0 : ref.contactObjId;
        // Get customerID from customerData data saved into local storage.
        var customerData = getFromLocalStorageByKey("customerData");
        LoggerInstance.debug("customerData: ", customerData);
        var customerId = (ref1 = customerData) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.id;
        var payload = {
            addressId: addressId,
            customerId: customerId,
            contactObjId: contactObjId
        };
        return linkContactAndCustomerService(payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("linkContactAndCustomer", JSON.stringify(data));
            queryClient.setQueryData("linkContactAndCustomer", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - linkContactAndCustomer: ", error);
            queryClient.setQueryData("linkContactAndCustomer", errorMock("linkContactAndCustomer", error));
            localStorage.setItem("linkContactAndCustomer", JSON.stringify(errorMock("linkContactAndCustomer", error)));
        }
    });
};
