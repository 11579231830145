import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { setDxlCampaigns } from "@vfit/consumer/data-access";
import { handleEnv } from "@vfit/shared/providers";
import { AppMobile } from "@vfit/shared/data-access";
export var APP_SESSION_MOCK = {
    username: "john.does@vodafone.com",
    firstname: "COMBINATION",
    email: "",
    fiscalcode: "XBSBMN80A01F205J",
    status: "authenticated",
    contactId: "301786062",
    customerId: "57349915",
    installationId: "8e02d6b1d6314c1d86ea3ddcc732d046",
    type_sim: "ricaricabile",
    osVersion: "13",
    profile: "A00",
    appVersion: "13.1.0",
    appPlatform: "Android",
    value: "3444709337",
    type: "sim",
    sessionId: "ZAsFe7wZ7ShP3caiS239nzkAtvG7z9Z1",
    mid: "29148987287202002034238077340243094341",
    secretKey: "",
    token: "eyJhbGciOiJSUzI1NiJ9.eyJzZXNzaW9uX2lkIjoiWkFzRmU3d1o3U2hQM2NhaVMyMzluemtBdHZHN3o5WjEiLCJzdWIiOiJ2b2RhZm9uZS1keGwiLCJpc3MiOiJqcy1sb2dpbiIsImF1ZCI6ImF1ZGllbmNlX3ZhbHVlIiwiZXhwIjoxNjc4NDQ3NDk5fQ.H3aD5b8E8sjK8ZZ4AwZJU0zjbHzRLhtwozy4XkmmeUSutIfr7Q5ulu23YKlyBk_hia41r7MTL13WfiMYBOBxYuGGW7OEFzG2xvUmbKD9nyf6phjD-aXSpdxMdcw2quX8PJhtnjOM9UUKL3Ghnv-QKPZpEUNVx_I8ck5_092_K2_u0FXhcL6YgTEQJlDIoQsPQ6uCMowgnMBlZ0ZBNJizIjmx6B8jG0RxStRyEvuNWAR3rc3wfGnUcnBVzLRfZY9GPWuwtnjnBACtkGFwQKETelnKRfjf_bp_oMyughznrqjpDbzs3yM0tHQAMz3ElWZGMOHRft4wRHDJjy23tNIBDA",
    customerUserAgent: 'MyVodafone/android/s/13/13.1.0/2.75/custom/"Google"/"sdk_gphone64_arm64"',
    pbo: "rt4Gm8uW%2FpQwlGh5%2BSAEWQ%3D%3D",
    isOverlay: true,
    isNext: true,
    role: "",
    items: [
        {
            encryptedValue: "Eu%2BxK%2BBD7xyo8c43uPBQbQ%3D%3D",
            planType: "prepaid",
            type: "sim",
            value: "3339418214"
        },
        {
            encryptedValue: "Eu%2BxK%2BBD7xyo8c43uPBQbQ%3D%3D",
            planType: "prepaid",
            type: "landline",
            value: "081942783"
        },
        {
            encryptedValue: "Eu%2BxK%2BBD7xyo8c43uPBQbQ%3D%3D",
            planType: "prepaid",
            type: "sim",
            value: "3339418217"
        }, 
    ]
};
export var getDxlAppMock = function() {
    var dxlIdAppMock = localStorage.getItem("dxlIdAppMock");
    return {
        mop: "CR",
        products: [
            {
                id: {
                    treatmentCode: "e462.6a6.ffffffff83ed2431.ffffffff8d2f2cd1",
                    campaignId: "000001701"
                },
                name: "Vodafone Club",
                type: "addon",
                productType: "NBA",
                description: "Vantaggi fissi tutti i mesi nelle principali categoria di spesa",
                longDescription: "Con Vodafone Club hai vantaggi fissi tutti i mesi nelle principali categorie di spesa per un risparmio minimo di 15€ al mese! Ecco alcuni esempi di sconto: 5€ di buono carburante su 30€ di rifornimento presso i distributori Q8, 10€ di sconto su 99€ di spesa minima presso i supermercati Carrefour, 5% di sconto per le prenotazioni su Booking.com, 30€ di sconto su 150€ di acquisti su Yoox.com. In pi\xf9 ogni mese hai 10 Giga aggiuntivi e il servizio 5G della Rete Vodafone sul tuo numero mobile. Iscriviti a soli 0,99€ il primo mese per godere di tutti i benefici dedicati ai clienti Vodafone Club. Rinnovi successivi a 3,99€ al mese.",
                characteristics: [],
                campaignDetail: {
                    bolloDescription: "Primo mese a 0.99€",
                    featureDescription: [],
                    price: [
                        {
                            priceRef: "campaign",
                            value: "3.99",
                            priceType: "recurring"
                        },
                        {
                            priceRef: "campaign",
                            value: "0.00",
                            priceType: "oneshot"
                        },
                        {
                            priceRef: "market",
                            value: "3.99",
                            priceType: "recurring"
                        },
                        {
                            priceRef: "market",
                            value: "0.00",
                            priceType: "oneshot"
                        }, 
                    ],
                    components: {
                        retrieveSessionId: "7bcaf301-1d54-4102-bc3f-dfcece378009",
                        billingOffer: [
                            {
                                id: "5472183",
                                price: []
                            },
                            {
                                id: "7418483",
                                price: []
                            }, 
                        ]
                    }
                },
                activationDate: "2023-03-10"
            },
            {
                id: {
                    treatmentCode: "ea0e.627.ffffffffadb41bd2.ffffffffe82a88ba",
                    campaignId: "000001574"
                },
                name: "1 Internet Unlimited Special Edition",
                type: "fixed change plan",
                productType: "NBA",
                description: "Per te che sei gi\xe0 cliente Vodafone, naviga senza limiti fino a 2.5 Gigabit. Offerta internet casa tutto incluso, compreso il modem e il costo di attivazione di 5€ al mese per 24 mesi",
                longDescription: "Per te che sei gi\xe0 cliente Vodafone mobile la fibra non \xe8 mai stata cos\xec conveniente! Internet illimitato fino ad 2.5 Gigabit al secondo al prezzo scontato di 19,90€ al mese, compreso il modem Vodafone Wi-Fi 6 Station e il costo di attivazione di 5€ al mese per 24 mesi. In pi\xf9 con Giga Family hai 50 Giga extra alla massima velocit\xe0 della Rete Vodafone sul tuo numero mobile. Puoi inoltre personalizzare la tua offerta con: chiamate verso fissi e mobili nazionali a 3€ in pi\xf9 al mese, Rete Sicura Family a 3.99€ € in pi\xf9 al mese. Lo sconto a 19,90€ al mese \xe8 valido solo se mantieni attive sia la tua offerta mobile che quella di rete fissa. Se disattivi la componente mobile pagherai 27,90€ euro al mese.",
                bundlePO: "46399883",
                characteristics: [],
                campaignDetail: {
                    featureDescription: [],
                    price: [],
                    components: {
                        retrieveSessionId: "7bcaf301-1d54-4102-bc3f-dfcece378009",
                        billingOffer: []
                    }
                },
                cmsCategory: "nba",
                cmsCustomization: {
                    activationCost: {
                        price: " ",
                        frequency: " "
                    },
                    renewalCost: {
                        price: "19,90 €",
                        frequency: "al mese"
                    },
                    card: {
                        shortDescription: "La potenza della nostra Rete e Giga in regalo sulla tua SIM mobile ad un prezzo irripetibile.",
                        primaryCTA: {
                            label: "Ti chiamiamo gratis",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        defaultCTA: {
                            label: "Scopri di pi\xf9",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        commercialName: "Unisci Wi-Fi di casa e mobile",
                        hero: {
                            type: "image",
                            path: "https://cdn.mvacloud-dev.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/01_dicembre_22/Card_catalog_MVA12_Lockin_v2.jpg"
                        }
                    },
                    detail: {
                        header: {
                            shortDescription: "Naviga a casa, senza limiti.",
                            featureList: [
                                "19.,90€ al mese",
                                "compreso il costo di attivazione di 5€ al mese per 24 mesi", 
                            ]
                        },
                        offer: {
                            title: "I vantaggi della Fibra Vodafone",
                            description: "La comodit\xe0 \xe8 di casa: naviga senza limiti con la migliore tecnologia, tutta la potenza della Rete Vodafone e il Modem con Wi-Fi ottimizzato incluso, per un segnale perfetto per tutti i device collegati.",
                            media: {
                                type: "image"
                            }
                        }
                    }
                },
                activationDate: "2023-03-10"
            },
            {
                id: {
                    treatmentCode: "ea0e.627.ffffffffadb41bd2.ffffffffe82a88ba",
                    campaignId: "000001574"
                },
                name: "2 Internet Unlimited Special Edition",
                type: "fixed change plan",
                productType: "NBA",
                description: "Per te che sei gi\xe0 cliente Vodafone, naviga senza limiti fino a 2.5 Gigabit. Offerta internet casa tutto incluso, compreso il modem e il costo di attivazione di 5€ al mese per 24 mesi",
                longDescription: "Per te che sei gi\xe0 cliente Vodafone mobile la fibra non \xe8 mai stata cos\xec conveniente! Internet illimitato fino ad 2.5 Gigabit al secondo al prezzo scontato di 19,90€ al mese, compreso il modem Vodafone Wi-Fi 6 Station e il costo di attivazione di 5€ al mese per 24 mesi. In pi\xf9 con Giga Family hai 50 Giga extra alla massima velocit\xe0 della Rete Vodafone sul tuo numero mobile. Puoi inoltre personalizzare la tua offerta con: chiamate verso fissi e mobili nazionali a 3€ in pi\xf9 al mese, Rete Sicura Family a 3.99€ € in pi\xf9 al mese. Lo sconto a 19,90€ al mese \xe8 valido solo se mantieni attive sia la tua offerta mobile che quella di rete fissa. Se disattivi la componente mobile pagherai 27,90€ euro al mese.",
                bundlePO: "36399883",
                characteristics: [],
                campaignDetail: {
                    featureDescription: [],
                    price: [],
                    components: {
                        retrieveSessionId: "7bcaf301-1d54-4102-bc3f-dfcece378009",
                        billingOffer: []
                    }
                },
                cmsCategory: "nba",
                cmsCustomization: {
                    activationCost: {
                        price: " ",
                        frequency: " "
                    },
                    renewalCost: {
                        price: "19,90 €",
                        frequency: "al mese"
                    },
                    card: {
                        shortDescription: "La potenza della nostra Rete e Giga in regalo sulla tua SIM mobile ad un prezzo irripetibile.",
                        primaryCTA: {
                            label: "Ti chiamiamo gratis",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        defaultCTA: {
                            label: "Scopri di pi\xf9",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        commercialName: "Unisci Wi-Fi di casa e mobile",
                        hero: {
                            type: "image",
                            path: "https://cdn.mvacloud-dev.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/01_dicembre_22/Card_catalog_MVA12_Lockin_v2.jpg"
                        }
                    },
                    detail: {
                        header: {
                            shortDescription: "Naviga a casa, senza limiti.",
                            featureList: [
                                "19.,90€ al mese",
                                "compreso il costo di attivazione di 5€ al mese per 24 mesi", 
                            ]
                        },
                        offer: {
                            title: "I vantaggi della Fibra Vodafone",
                            description: "La comodit\xe0 \xe8 di casa: naviga senza limiti con la migliore tecnologia, tutta la potenza della Rete Vodafone e il Modem con Wi-Fi ottimizzato incluso, per un segnale perfetto per tutti i device collegati.",
                            media: {
                                type: "image"
                            }
                        }
                    }
                },
                activationDate: "2023-03-10"
            },
            {
                id: {
                    treatmentCode: "ea0e.627.ffffffffadb41bd2.ffffffffe82a88ba",
                    campaignId: "000001574"
                },
                name: "Internet Unlimited Special Edition",
                type: "fixed change plan",
                productType: "NBA",
                description: "Per te che sei gi\xe0 cliente Vodafone, naviga senza limiti fino a 2.5 Gigabit. Offerta internet casa tutto incluso, compreso il modem e il costo di attivazione di 5€ al mese per 24 mesi",
                longDescription: "Per te che sei gi\xe0 cliente Vodafone mobile la fibra non \xe8 mai stata cos\xec conveniente! Internet illimitato fino ad 2.5 Gigabit al secondo al prezzo scontato di 19,90€ al mese, compreso il modem Vodafone Wi-Fi 6 Station e il costo di attivazione di 5€ al mese per 24 mesi. In pi\xf9 con Giga Family hai 50 Giga extra alla massima velocit\xe0 della Rete Vodafone sul tuo numero mobile. Puoi inoltre personalizzare la tua offerta con: chiamate verso fissi e mobili nazionali a 3€ in pi\xf9 al mese, Rete Sicura Family a 3.99€ € in pi\xf9 al mese. Lo sconto a 19,90€ al mese \xe8 valido solo se mantieni attive sia la tua offerta mobile che quella di rete fissa. Se disattivi la componente mobile pagherai 27,90€ euro al mese.",
                bundlePO: dxlIdAppMock || "6399883",
                characteristics: [],
                campaignDetail: {
                    featureDescription: [],
                    price: [],
                    components: {
                        retrieveSessionId: "7bcaf301-1d54-4102-bc3f-dfcece378009",
                        billingOffer: []
                    }
                },
                cmsCategory: "nba",
                cmsCustomization: {
                    activationCost: {
                        price: " ",
                        frequency: " "
                    },
                    renewalCost: {
                        price: "19,90 €",
                        frequency: "al mese"
                    },
                    card: {
                        shortDescription: "La potenza della nostra Rete e Giga in regalo sulla tua SIM mobile ad un prezzo irripetibile.",
                        primaryCTA: {
                            label: "Ti chiamiamo gratis",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        defaultCTA: {
                            label: "Scopri di pi\xf9",
                            actionURL: "ancora_lockin_card_storytelling"
                        },
                        commercialName: "Unisci Wi-Fi di casa e mobile",
                        hero: {
                            type: "image",
                            path: "https://cdn.mvacloud-dev.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/01_dicembre_22/Card_catalog_MVA12_Lockin_v2.jpg"
                        }
                    },
                    detail: {
                        header: {
                            shortDescription: "Naviga a casa, senza limiti.",
                            featureList: [
                                "19.,90€ al mese",
                                "compreso il costo di attivazione di 5€ al mese per 24 mesi", 
                            ]
                        },
                        offer: {
                            title: "I vantaggi della Fibra Vodafone",
                            description: "La comodit\xe0 \xe8 di casa: naviga senza limiti con la migliore tecnologia, tutta la potenza della Rete Vodafone e il Modem con Wi-Fi ottimizzato incluso, per un segnale perfetto per tutti i device collegati.",
                            media: {
                                type: "image"
                            }
                        }
                    }
                },
                activationDate: "2023-03-10"
            },
            {
                id: {
                    treatmentCode: "dbd4.6a4.ffffffffe7fb19ea.61bc22fa",
                    campaignId: "000001699"
                },
                name: "Rete Sicura 2.0",
                type: "addon",
                productType: "NBA",
                description: "Per proteggere i tuoi dati personali e la navigazione internet del tuo dispositivo",
                longDescription: "Attiva Rete Sicura 2.0, servizio che protegge i tuoi dati personali (nomi utente, password, carte di credito, indirizzo mail) e la navigazione internet del tuo dispositivo. Con il Parental Control puoi filtrare i siti con contenuti inappropriati per i tuoi figli. Il servizio ID monitoring di Iris powered by Generali verifica la presenza dei tuoi dati su dark e deep Web, ti fornisce un riscontro immediato e ti aiuta a gestire eventuali danni. Inoltre, con il nuovo portale Coach digitale ricevi notizie, consigli e podcast per conoscere i rischi della rete e per imparare a difenderti meglio. Scopri il nuovo mondo della sicurezza per te e la tua famiglia a soli 0.99€ il primo mese, i rinnovi successivi a 1.99€ al mese.",
                characteristics: [],
                campaignDetail: {
                    bolloDescription: "Primo mese a 0.99€",
                    featureDescription: [],
                    price: [
                        {
                            priceRef: "campaign",
                            value: "1.99",
                            priceType: "recurring"
                        },
                        {
                            priceRef: "campaign",
                            value: "0.00",
                            priceType: "oneshot"
                        },
                        {
                            priceRef: "market",
                            value: "1.99",
                            priceType: "recurring"
                        },
                        {
                            priceRef: "market",
                            value: "0.00",
                            priceType: "oneshot"
                        }, 
                    ],
                    components: {
                        retrieveSessionId: "7bcaf301-1d54-4102-bc3f-dfcece378009",
                        billingOffer: [
                            {
                                id: "259530095",
                                price: []
                            },
                            {
                                id: "7418583",
                                price: []
                            }, 
                        ]
                    }
                },
                activationDate: "2023-03-10"
            }, 
        ]
    };
};
var bwjExecuteSetupViewConfiguration = function() {
    var ref;
    var is = function(type, val) {
        return ![
            undefined,
            null
        ].includes(val) && val.constructor === type;
    };
    var serialize = function(params) {
        return Object.keys(params).map(function(key) {
            var item = params[key];
            if (is(Array, item) || is(Object, item)) {
                item = JSON.stringify(item);
            }
            return "".concat(key, "=").concat(encodeURIComponent(item));
        }).join("&");
    };
    var param = {
        parameters: {
            hideTOBi: true,
            fullNoHeader: true
        }
    };
    (ref = window.BWB_JS) === null || ref === void 0 ? void 0 : ref.execute("setupViewConfiguration", serialize(param), null);
};
var isAppMock = function() {
    var nodeEnv = handleEnv("NODE_ENV") || "";
    var mockApp = handleEnv("NX_MOCK_APP") || "";
    if (mockApp) {
        var ref;
        return ((ref = mockApp === null || mockApp === void 0 ? void 0 : mockApp.toString()) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "true";
    }
    return nodeEnv && nodeEnv === "LOCAL" || nodeEnv && nodeEnv === "development";
};
var retrieveAppSession = function() {
    var ref;
    if (!AppMobile.checkIsApp()) return;
    bwjExecuteSetupViewConfiguration();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.setSessionCb = function(appSessionValue) {
        if (appSessionValue) {
            window.localStorage.setItem("appSession", JSON.stringify(appSessionValue));
            window.sessionStorage.setItem("dxlJwt", (appSessionValue === null || appSessionValue === void 0 ? void 0 : appSessionValue.token) || "");
        }
    };
    (ref = window.BWB_JS) === null || ref === void 0 ? void 0 : ref.execute("getSession", "", "setSessionCb");
    localStorage.setItem("isApp", "true");
    var cfAppMock = localStorage.getItem("cfAppMock");
    if (isAppMock()) {
        window.localStorage.setItem("appSession", JSON.stringify(_object_spread_props(_object_spread({}, APP_SESSION_MOCK), {
            fiscalcode: cfAppMock || APP_SESSION_MOCK.fiscalcode
        })));
        window.sessionStorage.setItem("dxlJwt", (APP_SESSION_MOCK === null || APP_SESSION_MOCK === void 0 ? void 0 : APP_SESSION_MOCK.token) || "");
        setDxlCampaigns(getDxlAppMock());
    }
};
export { isAppMock, retrieveAppSession };
