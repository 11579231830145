import { CMS_CONFIG, evaluateOfferDetails, getTechByKey, retrieveCartCookie, useCmsConfig } from "@vfit/consumer/data-access";
import { API, checkWindow, isAcceptedCookieLawGroup, PAGES } from "@vfit/shared/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { evaluateCmsData } from "./heroSliderConsumer.utils";
var useHeroSliderConsumer = function() {
    var ref, ref1, ref2, ref3;
    var page = usePageProvider().page;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var cookieCart = retrieveCartCookie();
    var ref4 = cookieCart || {}, offerId = ref4.offerId, placeId = ref4.placeId, userType = ref4.userType, technology = ref4.technology, address = ref4.address;
    var isAcceptedPrivacyPolicy = "production" === "development" ? true : isAcceptedCookieLawGroup("4");
    var isOkPlaceId = placeId && placeId !== "undefined";
    var displayedTechnology = technology === null || technology === void 0 ? void 0 : technology.displayedTecnology;
    var allTechs = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_TECNOLOGIES);
    var droppedCartHomepageCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_DROPPED_CART_HOMEPAGE);
    var globalconfig = (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) || {}).globalconfig;
    var panicMode = (globalconfig || {}).panicMode;
    if (checkWindow() && panicMode) {
        window.isPanicModeFixed = panicMode.enablePanicModeFixed === "true";
    }
    var cmsRawData = displayedTechnology ? getTechByKey(allTechs, displayedTechnology) : droppedCartHomepageCMS;
    var offerDetails = evaluateOfferDetails(allProducts, cookieCart === null || cookieCart === void 0 ? void 0 : cookieCart.cmsId);
    var cmsRefinedData = evaluateCmsData(cmsRawData, address, technology, userType, offerDetails === null || offerDetails === void 0 ? void 0 : offerDetails.price, offerDetails === null || offerDetails === void 0 ? void 0 : offerDetails.originalPrice);
    return {
        showCookieCart: Boolean(cookieCart) && isAcceptedPrivacyPolicy && isOkPlaceId && (droppedCartHomepageCMS === null || droppedCartHomepageCMS === void 0 ? void 0 : (ref2 = droppedCartHomepageCMS.droppedcarthomepage) === null || ref2 === void 0 ? void 0 : ref2.disableDroppedCart) != "true",
        cmsData: cmsRefinedData,
        redirectUrl: "".concat(cookieCart === null || cookieCart === void 0 ? void 0 : (ref3 = cookieCart.offerDetails) === null || ref3 === void 0 ? void 0 : ref3.redirectUrl, "?placeId=").concat(placeId, "&offerId=").concat(offerId, "&flowType=1&icmp=cbu_web_returning-visitor")
    };
};
export default useHeroSliderConsumer;
