import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getHubPath, retrieveProduct } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var recoverServiceabilityField = function(data, field) {
    var fieldRetrived = data === null || data === void 0 ? void 0 : data.filter(function(item) {
        return item.displayName === field;
    });
    if (!fieldRetrived || fieldRetrived.length === 0) return "";
    return fieldRetrived[0].displayValue;
};
var updateLocalProductUrl = function(page) {
    var hub = page ? getHubPath(page) : "";
    var prod = retrieveProduct(true);
    if (prod) {
        localStorage.setItem("tProd", JSON.stringify(_object_spread(_object_spread_props(_object_spread({}, prod), {
            shoppingCartUrl: window.location.href
        }), hub && {
            hubPath: hub
        })));
    }
};
var organizeLableShoppingCart = function(tech, originalTechType) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        editAddress: (tech === null || tech === void 0 ? void 0 : (ref = tech.technology) === null || ref === void 0 ? void 0 : (ref1 = ref.bestOfferChoice) === null || ref1 === void 0 ? void 0 : ref1.editAddress) || "",
        bestOfferChoice: (tech === null || tech === void 0 ? void 0 : (ref2 = tech.technology) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.bestOfferChoice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.text) === null || ref4 === void 0 ? void 0 : ref4.replace("$TECHNOLOGY", "".concat(originalTechType))) || "",
        bestOfferChoiceMap: (tech === null || tech === void 0 ? void 0 : (ref5 = tech.technology) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.bestOfferChoice) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.textForMap) === null || ref7 === void 0 ? void 0 : ref7.replace("$TECHNOLOGY", "".concat(originalTechType))) || ""
    };
};
var getLocalAddress = function() {
    var addressData = getFromLocalStorageByKey("createdAddress");
    var dataToDisplay = [
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.street) || "", " ").concat((addressData === null || addressData === void 0 ? void 0 : addressData.streetNumber) || ""),
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.postalCode) || "", " ").concat(addressData === null || addressData === void 0 ? void 0 : addressData.city),
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.stateOrProvince) || ""), 
    ];
    return _object_spread_props(_object_spread({}, addressData), {
        city: (addressData === null || addressData === void 0 ? void 0 : addressData.city) || "",
        postalCode: (addressData === null || addressData === void 0 ? void 0 : addressData.postalCode) || "",
        stateOrProvince: (addressData === null || addressData === void 0 ? void 0 : addressData.stateOrProvince) || "",
        street: (addressData === null || addressData === void 0 ? void 0 : addressData.street) || "",
        streetNumber: (addressData === null || addressData === void 0 ? void 0 : addressData.streetNumber) || "",
        latitude: Number(addressData === null || addressData === void 0 ? void 0 : addressData.latitude) || 0,
        longitude: Number(addressData === null || addressData === void 0 ? void 0 : addressData.longitude) || 0,
        displayedAddress: dataToDisplay.join(", ")
    });
};
export { recoverServiceabilityField, updateLocalProductUrl, organizeLableShoppingCart, getLocalAddress };
