import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useQuery, useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { ErrorCodes, errorMock } from "@vfit/consumer/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * PayMean Service call
 * Method: GET
 * @param customerId
 * @param owningIndividualId
 * @param searchParams
 * @param customOptions
 */ var getPayMean = function(customerId, owningIndividualId, searchParams, customOptions) {
    return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId, "/individual/").concat(owningIndividualId, "/").concat(API.PAYMEAN), _object_spread({
        searchParams: _object_spread({}, searchParams)
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * PayMean Service call.
 * Method: GET
 * SearchParams:
 * salesChannel: 'selfService',
 * levelOfData: 'amount,paymentMethod,paymentDistribution',
 * @param customerId
 * @param owningIndividualId
 * @param searchParams
 * @param customOptions
 * @returns
 */ var payMeanService = function(customerId, owningIndividualId, searchParams, customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "payMean";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId, "/individual/").concat(owningIndividualId, "/").concat(API.PAYMEAN), {
                searchParams: _object_spread({}, searchParams)
            });
        },
        options: _object_spread({}, customOptions && _object_spread({}, customOptions))
    };
    return handleUseQuery(options);
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the payMeanService
 * @param enabler
 * @param isLogged
 * @returns
 */ export var usePayMean = function() {
    var enabler = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, isLogged = arguments.length > 1 ? arguments[1] : void 0;
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
    LoggerInstance.log("+++++ payMean  ");
    LoggerInstance.debug("+++++ payMean: enabler paymean", enabler);
    var queryClient = useQueryClient();
    var customerQuery = queryClient.getQueryData("customerData");
    LoggerInstance.log("payMean customerQuery: ", customerQuery);
    var customerSaved = localStorage.getItem("customerData");
    var customerFromStorage = !!customerSaved && JSON.parse(customerSaved);
    LoggerInstance.debug("payMean customer in localstorage : ", customerFromStorage);
    var customerData = customerFromStorage || customerQuery;
    var customerId = customerData && ((ref = customerData) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id);
    LoggerInstance.log("payMean ---> customerId", customerId);
    var owningIndividualId = customerData && ((ref2 = customerData) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.owningIndividual) === null || ref4 === void 0 ? void 0 : ref4.id);
    LoggerInstance.log("payMean ---> owningIndividualId", owningIndividualId);
    var billingQuery = queryClient.getQueryData("billingAccount");
    var billingAccountSaved = localStorage.getItem("billingAccount");
    var billingAccountFromStorage = !!billingAccountSaved && JSON.parse(billingAccountSaved);
    LoggerInstance.log("+++++ payMean billingAccountFromStorage : ", billingAccountFromStorage);
    var billingResponseData = billingAccountFromStorage || billingQuery;
    var billingId = billingResponseData && ((ref5 = billingResponseData) === null || ref5 === void 0 ? void 0 : ref5.id);
    var paymentQuery = queryClient.getQueryData("paymentData"); // adjust type
    LoggerInstance.log("+++++ payMean paymentQuery : ", paymentQuery);
    var paymentSaved = localStorage.getItem("paymentData");
    LoggerInstance.debug("+++++ payMean paymentSaved : ", paymentSaved);
    var paymentFromStorage = !!paymentSaved && JSON.parse(paymentSaved);
    LoggerInstance.debug("+++++ payMean paymentFromStorage : ", paymentFromStorage);
    // only for NextCustomer get validateDuplicateOrder obj from localStorage
    var validateDuplicateOrderSaved = localStorage.getItem("validateDuplicateOrder"); // adjust type
    var validateDuplicateOrder = !!validateDuplicateOrderSaved && validateDuplicateOrderSaved !== "undefined" && JSON.parse(validateDuplicateOrderSaved);
    LoggerInstance.log("payMean validateDuplicateOrder : ", validateDuplicateOrder);
    var authorizationSaved = localStorage.getItem("authorization"); // adjust type
    var authorizationFromStorage = !!authorizationSaved && JSON.parse(authorizationSaved);
    LoggerInstance.log("payMean authorizationFromStorage : ", authorizationFromStorage);
    /**
   *   Searching for NextCustomer into findCaller customerId from findCaller from localStorage or getQueryData
   */ var findCallerSaved = localStorage.getItem("findCaller");
    var findCaller = !!findCallerSaved && JSON.parse(findCallerSaved);
    var recognizedUser = ((ref6 = findCaller) === null || ref6 === void 0 ? void 0 : ref6.resultInfo) === "existingNextCustomer" && ((ref7 = findCaller) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.customerRef) === null || ref8 === void 0 ? void 0 : ref8.id);
    LoggerInstance.log("payMean !!recognizedUser : ", recognizedUser);
    var payMenCondition = !!authorizationFromStorage && !!paymentFromStorage || !!paymentQuery;
    LoggerInstance.log("payMean !!payMenCondition : ", payMenCondition);
    LoggerInstance.log("payMean queryEnabler !!customerId: ", !!customerId);
    LoggerInstance.log("payMean queryEnabler !!owningIndividualId: ", !!owningIndividualId);
    LoggerInstance.log("payMean queryEnabler !!billingId: ", !!billingId);
    /**
   *   Calling payMean when retrieved NextCustomer from findCaller or payment authorized
   */ var queryEnabler = false;
    if (!isLogged) {
        queryEnabler = !!customerId && (!!recognizedUser && !!validateDuplicateOrder || !!owningIndividualId && !!billingId && payMenCondition) && enabler;
    } else {
        queryEnabler = !!customerId && enabler;
    }
    LoggerInstance.log("+++++ payMean queryEnabler : ", queryEnabler);
    return payMeanService(customerId, owningIndividualId, {
        salesChannel: "selfService",
        levelOfData: "amount,paymentMethod,paymentDistribution"
    }, {
        keyDependency: [
            "payMean"
        ],
        enabled: queryEnabler,
        onSuccess: function(data) {
            LoggerInstance.log("payMean data : ", data);
            // save payMean object into storage
            localStorage.setItem("payMean", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - payMean: ", error);
            localStorage.setItem("payMean", JSON.stringify(errorMock("payMean", error, ErrorCodes.PAYMEAN)));
        }
    });
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the payMeanService for mobile
 * @param enabler
 * @param isLogged
 * @returns
 */ export var useMobilePayMean = function() {
    var enabler = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, isLogged = arguments.length > 1 ? arguments[1] : void 0;
    var ref, ref1, ref2, ref3;
    var customerData = getFromLocalStorageByKey("customerData");
    var billingAccount = getFromLocalStorageByKey("billingAccount");
    var paymentData = getFromLocalStorageByKey("paymentData");
    var validateDuplicateOrder = getFromLocalStorageByKey("validateDuplicateOrder");
    var authorization = getFromLocalStorageByKey("authorization");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
    var owningIndividualId = customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id;
    var billingId = billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id;
    var recognizedUser = (findCaller === null || findCaller === void 0 ? void 0 : findCaller.resultInfo) === "existingNextCustomer" && (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
    var payMenCondition = !!authorization && !!paymentData;
    var queryEnabler;
    if (!isLogged) {
        queryEnabler = !!customerId && (!!recognizedUser && !!validateDuplicateOrder || !!owningIndividualId && !!billingId && payMenCondition) && enabler;
    } else {
        queryEnabler = !!customerId && enabler;
    }
    return payMeanService(customerId, owningIndividualId, _object_spread({
        salesChannel: "selfService",
        checkExistingInstallment: "true",
        autoPay: "false",
        filters: "type==bankAccount,creditCard",
        usePaymeanFiltering: "false"
    }, (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) && {
        billingArrangementId: billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id
    }), {
        keyDependency: [
            "payMean"
        ],
        enabled: queryEnabler,
        onSuccess: function(data) {
            localStorage.setItem("payMean", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - payMean: ", error, ErrorCodes.PAYMEAN);
            localStorage.setItem("payMean", JSON.stringify(errorMock("payMean", error, ErrorCodes.PAYMEAN)));
        }
    });
};
/**
 * NEW get paymean
 * This method will replace all get paymean
 * @param customOptions
 */ export var useGetPaymean = function(customOptions) {
    return useQuery([
        "payMean"
    ], function() {
        var ref, ref1, ref2;
        var customerData = getFromLocalStorageByKey("customerData");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        var owningIndividualId = customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.id;
        return getPayMean(customerId, owningIndividualId, _object_spread({
            salesChannel: "selfService",
            checkExistingInstallment: "true",
            autoPay: "false",
            filters: "type==bankAccount,creditCard",
            usePaymeanFiltering: "false"
        }, (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) && {
            billingArrangementId: billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id
        }));
    }, {
        enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) || false,
        onSuccess: function(data) {
            localStorage.setItem("payMean", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - payMean: ", error, ErrorCodes.PAYMEAN);
            localStorage.setItem("payMean", JSON.stringify(errorMock("payMean", error, ErrorCodes.PAYMEAN)));
        }
    });
};
